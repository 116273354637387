<template>
  <div class="dashboard-root-container">
    <span style="font-size: 23px; font-weight: bold">
      置顶看板
      <span style="margin-left: 20px; font-size: 16px; color: #ccc">{{
        topBoardList.length
      }}</span>
    </span>
    <div class="dashboard-wrap-container">
      <board-item
        @click.native="goTaskPanel(item)"
        v-for="item in topBoardList"
        :key="item.id"
        :dataSource="item"
        :isStar="true"
        @refresh="refresh"
      />
    </div>
    <div class="dashboard-row" style="margin-top: 50px">
      <span style="font-size: 23px; font-weight: bold">
        全部看板
        <span style="margin-left: 20px; font-size: 16px; color: #ccc">{{
          boardList.length
        }}</span>
      </span>
      <Icon
        @click="create"
        type="md-add-circle"
        :size="25"
        style="cursor: pointer"
      />
    </div>
    <div class="dashboard-wrap-container">
      <board-item
        @click.native="goTaskPanel(item)"
        v-for="item in boardList"
        :key="item.id"
        :dataSource="item"
        :isStar="false"
        @refresh="refresh"
      />
    </div>
    <board-drawer ref="boardDrawer" @refresh="refresh" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import BoardItem from "./Item/BoardItem";
import BoardDrawer from "./Drawer/BoardDrawer";
export default {
  components: {
    "board-item": BoardItem,
    "board-drawer": BoardDrawer,
  },
  data() {
    return {
      icons: icon,
      projectId: 0,
      topBoardList: [],
      boardList: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.refresh();
  },
  methods: {
    ...mapActions({
      taskGetBoardListAction: "taskGetBoardList",
    }),
    create() {
      this.$refs.boardDrawer.open();
    },
    goTaskPanel(item) {
      this.$router.push({
        name: "task-panel",
        params: {
          tid: item.task_board_id,
          pid: this.projectId,
        },
      });
    },
    refresh() {
      this.taskGetBoardListAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          this.topBoardList = res.top_list;
          this.boardList = res.normal_list;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.dashboard-root-container {
  display: flex;
  flex-direction: column;
  padding-right: 150px;
}
.dashboard-wrap-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}
.dashboard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>