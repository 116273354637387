<template>
  <div
    class="board-item-root-container"
    :style="{
      'background-image':
        'url(' +
        (dataSource.img_url ? dataSource.img_url : icons.boardBackground) +
        ')',
    }"
  >
    <div class="board-item-mask"></div>
    <span style="z-index: 1; color: #fff; font-weight: bold; font-size: 15px">{{
      dataSource.name
    }}</span>

    <Icon
      @click.stop="setTop"
      type="md-star"
      :color="isStar ? '#f0c347' : '#999'"
      :size="20"
      style="align-self: flex-end; z-index: 1"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
export default {
  props: {
    dataSource: Object,
    isStar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: icon,
      projectId: 0,
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
  },
  methods: {
    ...mapActions({
      taskSetBoardStateAction: "taskSetBoardState",
    }),
    setTop() {
      this.taskSetBoardStateAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        task_board_id: this.dataSource.task_board_id,
        state: this.isStar ? 1 : 2, //1 正常 2 置顶
      })
        .then((res) => {
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.board-item-root-container {
  position: relative;
  width: 240px;
  height: 140px;
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
  overflow: hidden;
}
.board-item-mask {
  opacity: 0.3;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
}
</style>