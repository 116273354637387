<template>
  <my-drawer ref="boardDrawer" title="新建/编辑看板">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-single-image
          class="board-form-image"
          ref="formImage"
          :before-upload="handleBeforeUpload"
          :img-url="boardForm.img_url"
        ></my-single-image>

        <my-input
          ref="formName"
          title="名称"
          v-model="boardForm.name"
          :validate="nameValidate"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as oss from "../../../utils/ossHelper";
import MySingleImage from "../../../components/Form/MySingleImage";
import MyInput from "../../../components/Form/MyInput";
import MyDrawer from "../../../components/MyDrawer";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-input": MyInput,
    "my-single-image": MySingleImage,
  },
  data() {
    return {
      boardForm: {
        employer_id: 0,
        project_id: 0,
        task_board_id: -1,
        img_url: null,
        name: "",
      },
    };
  },
  mounted() {
    this.boardForm.employer_id = localStorage.getItem("employer_id");
    this.boardForm.project_id = this.$router.currentRoute.params.id;
  },
  methods: {
    ...mapActions({
      taskAddOrUpdateBoardAction: "taskAddOrUpdateBoard",
    }),
    open() {
      this.$refs.boardDrawer.open();
    },
    submit() {
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.taskAddOrUpdateBoardAction(this.boardForm)
            .then((res) => {
              this.$refs.boardDrawer.close();
              this.$emit("refresh");
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 图片上传 */
    handleBeforeUpload(file) {
      this.$Spin.show();
      oss.upload(file, "board").then((result) => {
        this.boardForm.img_url = result.fullPath;
        this.$Spin.hide();
      });
      return false;
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.boardForm.name == "") {
          reject("请填写看板名称");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.board-form-image {
  width: 100px;
  height: 118px;
}
</style>